import React from 'react'

import loadable from "@loadable/component";
import useGetHook from '../../customHooks/useGetHook';
import APIS from '../../contants/EndPoints';
import usePostHook from '../../customHooks/usePostHook';

const SettingsComponent = loadable(() => import("../../components/Settings/Settings"));

const Settings = () => {
  const { data: settingsData, isLoading } = useGetHook({
    queryKey: ['settings'],
    url: `${APIS.SETTINGS}`,
    params: {}
  });

  const { isPostLoading, mutateAsync, postData } = usePostHook({ queryKey: ["settings"], navigateURL: null })
  const handleCreateSettings = async (values: any, selectedValues: any) => {
    const url = `${APIS.SETTINGS}`
    const formData = {
      ...values,
      default_holiday: selectedValues,
    }
    try {
      const res = await mutateAsync({ url, formData })
    } catch (err) {
      console.log(err);
    }
  }


  return (<>
    {isLoading ? "isLoading" :
      <SettingsComponent
        settingsData={settingsData}
        handleCreateSettings={handleCreateSettings}
      />
    }
  </>
  )
}

export default Settings


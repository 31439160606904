import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PageNotFound from "../pages/ErrorPage/PageNotFound";
import DashboardLayout from "../layouts/DashboardLayout";
import Dashboard from "../pages/Dashboard/Dashboard";
import Settings from "../pages/Settings/Settings";
import UserCalendar from "../pages/UserCalendar/UserCalendar";


// import AccessControl from "../helpers/accessControl";
// import PermissionDenied from "../pages/Error/PermissionDenied";

// https://adarshaacharya.com.np/blog/role-based-auth-with-react-router-v6

/**
 * Top level application router
 *
 * @returns {JSX.Element}
 */
const Router = () => {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <DashboardLayout />
          </PrivateRoute>
        }
      >
        <Route path={"/"} element={<Dashboard />} />
        <Route path={"/user-calendar"} element={<UserCalendar />} />
        <Route path={"/settings"} element={<Settings />} />



        <Route path="/*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

export default Router;

import React, { useEffect, useState } from 'react'
import loadable from "@loadable/component";
import APIS from '../../contants/EndPoints';
import useGetHook from '../../customHooks/useGetHook';
import moment from 'moment';
import randString from '../../utils/randomString';
import { NepaliDatePicker, BSDate } from "nepali-datepicker-react";

const UserCalendarComponent = loadable(() => import("../../components/UserCalendar/UserCalendar"));

const UserCalendar = (props: any) => {
  const [currentCalendarType, setCurrentCalendarType] = useState("AD")
  const now = new BSDate().now();
  const [currentDate, setCurrentDate] = useState(null)
  const [currentMonth, setCurrentMonth] = useState(null)
  const [currentYear, setCurrentYear] = useState(currentCalendarType == "AD" ? moment().year() : now.year())
  const [action, setAction] = useState(0)
  const [refetchQuery, setRefetchQuery] = useState("calendar")

  const { data, isLoading } = useGetHook({
    queryKey: [refetchQuery],
    url: `${APIS.CALENDAR}month-calendar`,
    params: {
      "year": currentYear,
      "month": currentCalendarType == "BS" ? `${currentDate === null ? now.month() : currentDate + 1}` : currentDate === null ? moment().month() + 1 : currentDate + 1,
      "calendar_type": currentCalendarType,
      "action": action
    }
  });
  useEffect(() => {
    if (currentCalendarType === "AD") {
      setCurrentDate(moment().month())
      setCurrentYear(moment().year())
    }
    else {
      setCurrentDate(now.month())
      setCurrentYear(now.year())
    }
    setRefetchQuery(randString(32))
  }, [currentCalendarType])

  useEffect(() => {
    if (data) {
      if (currentCalendarType === "AD") {
        setCurrentDate(data?.month_list.indexOf(data?.month))
        setCurrentYear(data?.year)
      }
      else {
        setCurrentDate(data?.month_list.indexOf(data?.month))
        setCurrentYear(data?.year)
      }
    }
  }, [data])


  return (
    <>
      {isLoading ? "isLoading" :
        < UserCalendarComponent
          data={data}
          setCurrentDate={setCurrentDate}
          setCurrentMonth={setCurrentMonth}
          currentMonth={currentMonth}
          setCurrentYear={setCurrentYear}
          setCurrentCalendarType={setCurrentCalendarType}
          currentCalendarType={currentCalendarType}
          setRefetchQuery={setRefetchQuery}
          setAction={setAction}
          action={action}
          currentDate={currentDate}
        />
      }
    </>
  )
}

export default UserCalendar